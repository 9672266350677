import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Accordion from '../components/accordion';

import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';

type FaqPageProps = {
  pageContext: {
    language: string;
  };
  data: any;
};

const FaqPage = ({ pageContext, data }: FaqPageProps) => {
  const { language } = pageContext;
  const intl = useIntl();

  const faqData = data.allPrismicFaqsPage.edges[0].node.data;

  return (
    <Layout lang={language}>
      <SEO title={faqData.title} lang={language} />
      <Breadcrumb className="breadcrumb--workbench">
        <BreadcrumbAnchor
          label={intl.formatMessage({
            id: 'home',
            defaultMessage: 'home',
          })}
          url="/"
        />
        <BreadcrumbDivider />
        <BreadcrumbAnchor active label={faqData.title} />
      </Breadcrumb>

      <Section
        className="hero hero--faqsPage"
        title="FAQs"
        description={faqData.description.html}
      />

      <Section className="dt-faq__list">
        {faqData.body.map(({ primary, items }, idx_group) => (
          <div className="dt-faq__group" key={`dt-faq__group-${idx_group}`}>
            <h3 className="dt-faq__group-title">{primary.section_title}</h3>
            {items.length &&
              items.map((item, idx_content) => (
                <Accordion
                  key={`accordion-group_${idx_group}-${idx_content}`}
                  question={item.question}
                  answer={item.answer_content.html}
                />
              ))}
          </div>
        ))}
      </Section>
    </Layout>
  );
};
export const query = graphql`
  query FaqPageQuery($prismicLang: String) {
    allPrismicFaqsPage(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          id
          lang
          data {
            description {
              html
            }
            headline
            title
            body {
              ... on PrismicFaqsPageDataBodySection {
                id
                primary {
                  section_title
                }
                items {
                  question
                  answer_content {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(FaqPage);
