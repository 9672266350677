import React from 'react';

type SectionProps = {
  title?: string;
  tagline?: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;
  id?: string;
};

const Section = ({
  className,
  title,
  tagline,
  description,
  children,
  id,
}: SectionProps) => {
  return (
    <section
      className={`${className ? `m-section ${className}` : 'm-section'}`}
      id={id}
    >
      <div className="m-section__wrap">
        {(tagline || title || description) && (
          <div className="m-section__header">
            {tagline && <p className="m-section__tagline">{tagline}</p>}
            {title && <h2 className="m-section__title">{title}</h2>}
            {description && (
              <div
                className="m-section__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
        )}
        <div className="m-section__content">{children}</div>
      </div>
    </section>
  );
};

export default Section;
