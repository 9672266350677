import React from 'react';
import IntlLink from '../link';
type BreadcrumbProps = {
  children: React.ReactNode;
  className: string;
};
export const Breadcrumb = ({ children, className }: BreadcrumbProps) => {
  return (
    <section className={`breadcrumb ${className ? className : ''}`}>
      {children}
    </section>
  );
};

type AnchorProps = {
  label: string;
  url?: string;
  active?: boolean;
};

export const BreadcrumbAnchor = ({ label, url, active }: AnchorProps) => {
  return (
    <>
      {!active ? (
        <IntlLink className="breadcrumb__anchor" to={url} label={label} />
      ) : (
        <span className="breadcrumb__active">{label}</span>
      )}
    </>
  );
};

export const BreadcrumbDivider = () => {
  return (
    <svg className="breadcrumb__divider">
      <use xlinkHref="#chevron-right"></use>
    </svg>
  );
};
