import React, { useState } from 'react';
import styled from 'styled-components';
import MinusIcon from '../../assets/icons/minus.svg';
import PlusIcon from '../../assets/icons/plus.svg';

const Article = styled.article`
  ul {
    list-style-type: initial;

    padding: inherit;
  }
`;

type AccordionProps = {
  question: string;
  answer: string;
};
const Accordion = ({ question, answer }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Article className="dt-accordion">
      <div
        className={`dt-accordion__header ${
          isOpen ? 'dt-accordion__header--is-open' : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="dt-accordion__question">{question}</h4>
        <div className="dt-accordion__toggler">
          <img src={isOpen ? MinusIcon : PlusIcon} alt="Toggle FAQ Item" />
        </div>
      </div>

      <div
        className={`dt-accordion__answer ${
          isOpen ? 'dt-accordion__answer--is-open' : ''
        }`}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </Article>
  );
};

export default Accordion;
